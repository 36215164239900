import { IPicMode } from "store";
import { IFavImage } from "types";

export class Fav {
  url = "https://ycg4su.deta.dev/api/pics/";
  authcode: string | null;

  constructor(authcode: string | null) {
    this.authcode = authcode;
  }

  static filter(favs: IFavImage[], mode?: IPicMode) {
    switch (mode) {
      case undefined:
        return favs;
      case 'OnlyHorizontalPic':
        return favs.filter(fav => fav.width >= fav.height)
      case 'OnlyVerticalPic':
        return favs.filter(fav => fav.width <= fav.height)
    }
  }
  add(newFav: IFavImage) {
    fetch(this.url, {
      method: 'POST',
      body: JSON.stringify({
        ...newFav,
        modifiedAt: new Date().getTime(),
      }),
      headers: {
        'x-authcode': `${this.authcode}`,
      }
    })
  }
  async delete(picId: string) {
    const res = await fetch(this.url + picId, {
      method: 'DELETE',
      headers: {
        'x-authcode': `${this.authcode}`,
      }
    })
    if (res.status === 204) return true;
    return false;
  }
  async get() {
    const res = await fetch(this.url, {
      method: 'GET',
      headers: {
        'x-authcode': `${this.authcode}`,
      }
    })
    if (res.ok) {
      return await res.json();
    }
    return [];
  }
}