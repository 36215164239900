import styled from 'styled-components';
import { Menu } from '@material-ui/icons';
import React, { useState } from 'react';
import HanimePng from 'static/h.png';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { TagList } from './tags';

const SideNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 3rem;
  padding: 8px;
  height: 100vh;
  align-items: center;
  position: fixed;
`;

const SourceIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 1rem;
`

export const SideNav = () => {
  const [open, setOpen] = useState(false);
  const handleTagItemClick = (tag: any) => {
    setOpen(false)
  }
  return <SideNavWrapper>
    <SwipeableDrawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <TagList onItemClick={handleTagItemClick} />
    </SwipeableDrawer>
    <Menu onClick={() => setOpen(true)} />
    <SourceIcon src={HanimePng} alt="" />
  </SideNavWrapper>
}