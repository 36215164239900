import { Button, makeStyles } from '@material-ui/core';
import { MyPhotoProvider } from 'components/photoProvider';
import { useFavs } from 'hooks';
import React, { useCallback, useEffect } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import LazyLoad from 'react-lazyload';
import { PhotoConsumer } from 'react-photo-view';
import 'react-photo-view/dist/index.css';
import { useImmutableContext } from 'store';
import { IImage } from 'types';
import { betterImage } from 'utils';
import { ImageFlow } from 'api';

const useStyles = makeStyles({
  img: {
    margin: 2,
  },
});

function Home() {
  const authcode = localStorage.getItem('authcode');
  const { apply, state } = useImmutableContext()
  const { images = [], status: { loading }, settings: { picMode } } = state;
  const { addFavs } = useFavs();
  const classes = useStyles()

  const getData = useCallback(async (before_id?: string) => {
    apply(s => {
      s.status.loading = true
    })
    const imageFlow = new ImageFlow(authcode)
    const newImages = await imageFlow.get(before_id);
    apply(s => {
      s.images.push(...newImages)
      s.status.loading = false;
    })
  }, [apply, authcode]);

  const loadMore = async () => {
    if (!loading) {
      const lastImageId = images[images.length - 1].id;
      getData(lastImageId);
    }
  }

  useEffect(() => {
    if (!authcode) {
      const code = window.prompt("hi");
      code && localStorage.setItem('authcode', code)
    }
    if (!images.length) {
      getData();
    }
  }, [authcode, images.length, getData])

  const removeFromFav = (image: IImage) => {
    apply(s => {
      s.favs = s.favs.filter(item => item.url !== image.url);
    })
  }

  useBottomScrollListener(loadMore);

  const showImages = ImageFlow.filter(images, picMode);
  return (
    <>
      <MyPhotoProvider>
        {(showImages || []).map((image: IImage, index: number) => (
          <LazyLoad throttle={200} height={200} key={image.id}>
            <PhotoConsumer key={image.id} src={image.url} >
              <img
                src={betterImage(image.showUrl)}
                alt=""
                className={classes.img}
                style={{ height: 200, objectFit: 'contain', breakInside: 'avoid' }}
                referrerPolicy="no-referrer"
                draggable="true"
                onDragStart={(ev) => {
                  if (!ev.currentTarget.style.outline || ev.currentTarget.style.outline === "none") {
                    ev.currentTarget.style.outline = "2px dashed indigo";
                    addFavs(image);
                  } else {
                    ev.currentTarget.style.outline = 'none';
                    removeFromFav(image);
                  }
                }}
              />
            </PhotoConsumer>

          </LazyLoad>
        ))}
      </MyPhotoProvider>
      <Button fullWidth onClick={loadMore} disabled={loading}>more</Button>
    </>
  );
}

export default Home;

