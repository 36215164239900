import { IPicMode } from "store";
import { IImage, ResImage } from "types";

export class ImageFlow {
  apiBase = "https://pichub.gine.workers.dev/api/";
  authcode: string | null;

  constructor(authcode: string | null) {
    this.authcode = authcode;
  }

  static filter(favs: IImage[], mode?: IPicMode) {
    switch (mode) {
      case undefined:
        return favs;
      case 'OnlyHorizontalPic':
        return favs.filter(fav => fav.width >= fav.height)
      case 'OnlyVerticalPic':
        return favs.filter(fav => fav.width <= fav.height)
    }
  }
  async get(before_id?: string) {
    let url = this.apiBase + 'now';
    if (before_id) {
      url = this.apiBase + `before?id=${before_id}`;
    }
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'x-authcode': `${this.authcode}`,
      }
    })
    try {
      const resJson = await res.json();
      const newImages: IImage[] = resJson.data.map((item: ResImage) => {
        return {
          width: item.width,
          height: item.height,
          extension: item.extension,
          filename: item.filename,
          filesize: item.filesize,
          url: item.url,
          showUrl: item.canonical_url,
          proxyUrl: item.proxy_url,
          createdAt: item.created_at_unix,
          id: item.id,
        }
      })
      return newImages;
    } catch {
      return [];
    }
  }
}