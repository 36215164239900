import createImmutableContext from "immutable-context";
import { IFavImage, IAppStatus, IImage } from "types";


export type IPicMode = 'OnlyHorizontalPic' | 'OnlyVerticalPic';


type ISetting = {
  picMode?: IPicMode;
}

export type StateType = {
  images: IImage[];
  favs: IFavImage[];
  status: IAppStatus;
  settings: ISetting;
};

export const loadState = () => {
  const initState = {
    images: [],
    favs: [],
    status: {
      page: 0,
      total: 0,
      newCount: 0,
    },
    settings: {}
  };
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return initState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return initState;
  }
};

const { StateProvider, useImmutableContext } = createImmutableContext<
  StateType
>(loadState(), {
  onUpdate: s => {
    // try {
    //   localStorage.setItem("state", JSON.stringify(s));
    //   console.log("cache state 🆗");
    // } catch (error) {
    //   console.log("cache state ❌");
    // }
  }
});

export { StateProvider, useImmutableContext };