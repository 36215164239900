import { MyPhotoProvider } from 'components/photoProvider';
import { useFavs } from 'hooks';
import React from 'react';
import LazyLoad from 'react-lazyload';
import { PhotoConsumer } from 'react-photo-view';
import 'react-photo-view/dist/index.css';
import { betterImage } from 'utils';

function Fav() {
  const { favs } = useFavs();
  console.log({ favs })
  return (
    <MyPhotoProvider>
      {favs.map((image, index) => (
        <LazyLoad throttle={200} height={200} key={image.id}>
          <PhotoConsumer key={image.id} src={image.url} >
            <img src={betterImage(image.showUrl)} alt="" style={{ height: 200, objectFit: 'contain', breakInside: 'avoid' }} referrerPolicy="no-referrer" />
          </PhotoConsumer>
        </LazyLoad>
      ))}
    </MyPhotoProvider>
  );
}

export default Fav;