import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import { Button } from '@material-ui/core';
import { useImmutableContext } from 'store';
import ScreenLockLandscapeOutlinedIcon from '@material-ui/icons/ScreenLockLandscapeOutlined'; // 横屏
import ScreenLockPortraitOutlinedIcon from '@material-ui/icons/ScreenLockPortraitOutlined'; // 竖屏
import { IPicMode } from 'store';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function SwitchListSecondary() {
  const { state, apply } = useImmutableContext()
  const classes = useStyles();
  const defaultPicMode = state.settings.picMode ? [state.settings.picMode] : [];
  const [checked, setChecked] = React.useState<IPicMode[]>(defaultPicMode);

  const handleToggle = (value: IPicMode) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    apply(s => {
      s.settings.picMode = newChecked[0];
    })
  };

  const syncFavs = async () => {
    localStorage.removeItem('state')
    apply(s => {
      s.images = []
      localStorage.setItem('state', JSON.stringify(s))
    })

    const authcode = localStorage.getItem('authcode');
    const url = "/api/setFavs"
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(state.favs), // must match 'Content-Type' header
      mode: 'no-cors',
      headers: {
        'x-authcode': `${authcode}`,
        'content-type': 'application/json',
      }
    })
    console.log(res.body)
    const resJson = await res.json();
    if (resJson.success) {
      alert('保存成功')
    }
  }
  return (
    <List subheader={<ListSubheader>Settings</ListSubheader>} className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <ScreenLockLandscapeOutlinedIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-wifi" primary="只看横图" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            disabled={checked.some(item => item === "OnlyVerticalPic")}
            onChange={handleToggle('OnlyHorizontalPic')}
            checked={checked.indexOf('OnlyHorizontalPic') !== -1}
            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <ScreenLockPortraitOutlinedIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-bluetooth" primary="只看竖图" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            disabled={checked.some(item => item === "OnlyHorizontalPic")}
            onChange={handleToggle('OnlyVerticalPic')}
            checked={checked.indexOf('OnlyVerticalPic') !== -1}
            inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Button fullWidth onClick={syncFavs}>
        同步收藏
        </Button>
      <Button fullWidth onClick={() => {
        localStorage.removeItem('state')
        apply(s => {
          s.images = []
          localStorage.setItem('state', JSON.stringify(s))
        })
      }}>
        清空缓存
      </Button>
    </List>
  );
}
