import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import 'react-photo-view/dist/index.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Fav from 'pages/fav';
import Home from 'pages/home';
import Setting from 'pages/setting';

import { theme } from 'theme';
import { StateProvider } from 'store';
import { Layout } from 'layout';

function App() {
  const authcode = localStorage.getItem('authcode');

  useEffect(() => {
    if (!authcode) {
      const code = window.prompt("hi");
      code && localStorage.setItem('authcode', code)
    }
  }, [authcode])


  return (
    <Router>
      <StateProvider>
        <ThemeProvider theme={theme}>
          <Layout>
            <Switch>
              <Route path="/setting">
                <Setting />
              </Route>
              <Route path="/fav">
                <Fav />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Layout>
        </ThemeProvider >
      </StateProvider>
    </Router>
  );
}

export default App;

