import SimpleBottomNavigation from 'components/nav';
import { SideNav } from 'components/sideNav';
import React from 'react';
import styled from 'styled-components';


const MainWrapper = styled.div`
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 56px;
    height: fit-content;
    margin-left: 4rem;
`

export const Layout: React.FC = ({ children }) => {
  
  return (
    <div>

      <SideNav />
      <MainWrapper>
        {children}
      </MainWrapper>
      <SimpleBottomNavigation />
    </div>
  )
}