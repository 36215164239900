export const useTags = () => {
  return [
    {
      name: 'tag1',
      color: 'red',
    },
    {
      name: 'tag2',
      color: 'green',
    },
    {
      name: 'tag3',
      color: 'yellow',
    },
    {
      name: 'tag4',
      color: 'pink',
    },
  ]
}