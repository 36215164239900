import Checkbox from '@material-ui/core/Checkbox';
import Grow from '@material-ui/core/Grow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { useSelections, useClickAway } from 'ahooks';
import { useTags } from 'hooks';
import React, { useRef } from 'react';
import { IFavImage } from 'types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export function TagSelector({ data }: { data: IFavImage }) {
  const classes = useStyles();
  const tags = data.tags || [];
  const allTags = useTags();
  const allTagList = allTags.map(tag => tag.name);
  const {
    isSelected,
    toggle,
  } = useSelections(allTagList, tags);

  return (

    <List dense className={classes.root}>
      {allTagList.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value}`;
        return (
          <ListItem key={value} button onClick={() => toggle(value)}>
            <ListItemText id={labelId} primary={value} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                color="primary"
                onChange={() => toggle(value)}
                checked={isSelected(value)}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}


const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }),
);

export function TagMenu({ data }: { data: any }) {
  const classes = useStyles1();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(() => {
    setOpen(false);
  }, ref);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <span
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <LabelOutlinedIcon color="primary" />
      </span>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <div ref={ref}>
                <TagSelector data={data} />
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
