import { Favorite, Fullscreen, FullscreenExit, RotateLeftOutlined } from '@material-ui/icons';
import { useFullscreen } from 'ahooks';
import { Fav, ImageFlow } from "api";
import { default as React } from "react";
import { PhotoProvider } from 'react-photo-view';
import { StateType, useImmutableContext } from "store";
import styled from 'styled-components';
import { IImage } from "types";
import { TagMenu } from './tags';

const ToolbarItem = styled.span`
  box-sizing: border-box;
  padding: 10px;
  opacity: 0.75;
  cursor: pointer;
  width: 44px;
  height: 44px;
  transition: opacity 0.2s linear;
`

const getImages = (state: StateType, isFav: boolean) => {
  const { images, favs, settings: { picMode } } = state;
  if (isFav) {
    const res = Fav.filter(favs, picMode);
    return res;
  }
  return ImageFlow.filter(images, picMode);
}

export const MyPhotoProvider = (props: any) => {
  const authcode = localStorage.getItem('authcode');
  const { apply, state } = useImmutableContext()

  const isFav = window.location.pathname === '/fav';
  const currentImages = getImages(state, isFav);
  const [isFullscreen, { toggleFull }] = useFullscreen(() => document.getElementById('PhotoView_Slider'));
  const fav = new Fav(authcode);

  const addFavs = (image: IImage) => {
    apply(s => {
      const newFav = {
        ...image,
        favAt: new Date().getTime(),
        tags: [],
      }

      s.favs.push(newFav)
      fav.add(newFav)
    })
  }

  const deleteFav = (picId: string) => {
    apply(s => {
      s.favs = s.favs.filter(fav => fav.id !== picId)
    })
    fav.delete(picId)
  }

  return <PhotoProvider toolbarRender={({ index, onRotate, rotate }) => {
    const image = currentImages[index];

    const isImgInFavs = state.favs.find(img => img.id === image?.id)
    const handleFavClick = () => {
      if (isImgInFavs) {
        deleteFav(image.id)
        return;
      }
      addFavs(image)
    }
    return <>
      <ToolbarItem>
        <TagMenu data={image} />
      </ToolbarItem>
      <ToolbarItem>
        <Favorite onClick={handleFavClick} color={isImgInFavs ? 'error' : 'inherit'}></Favorite>
      </ToolbarItem>
      <ToolbarItem>
        <RotateLeftOutlined onClick={() => onRotate(rotate + 90)} />
      </ToolbarItem>
      <ToolbarItem>
        {
          isFullscreen ? <FullscreenExit onClick={toggleFull} /> : <Fullscreen onClick={toggleFull} />
        }
      </ToolbarItem>
    </>
  }} {...props} />
}
