import { Fav } from "api";
import { useEffect } from "react";
import { useImmutableContext } from "store";
import { IImage } from "types";

export const useFavs = () => {
  const authcode = localStorage.getItem('authcode');
  const { apply, state: { favs, settings: { picMode } } } = useImmutableContext()
  const addFavs = (image: IImage) => {
    const fav = new Fav(authcode);

    apply(s => {
      const newFav = {
        ...image,
        favAt: new Date().getTime(),
        tags: [],
      }

      s.favs.push(newFav)
      fav.add(newFav)
    })
  }

  useEffect(() => {
    const fav = new Fav(authcode);

    const loadFavs = async () => {
      apply(s => {
        s.status.loading = true;
      })
      const favs = await fav.get();
      apply(s => {
        s.favs = favs;
        s.status.loading = false;
      })
    }
    apply(s => {
      if (s.favs && s.favs.length) return;
      loadFavs()
    })
  }, [apply, authcode])

  return {
    favs: Fav.filter(favs, picMode),
    addFavs,
  }
}