import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTags } from 'hooks';
import ListSubheader from '@material-ui/core/ListSubheader';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      minWidth: 300,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

type Itag = {
  name: string;
  color: string;
}
interface ITagListProps {
  onItemClick: (tag: Itag) => void;
}
export function TagList({ onItemClick }: ITagListProps) {
  const classes = useStyles();
  const tags = useTags();
  return (
    <List
      subheader={<ListSubheader>标签</ListSubheader>}
      component="nav"
      className={classes.root}
      aria-label="tags"
    >
      {
        tags.map(tag => {
          return <ListItem key={tag.name} button onClick={() => onItemClick(tag)}>
            <ListItemIcon>
              <LabelOutlinedIcon style={{ color: tag.color }} />
            </ListItemIcon>
            <ListItemText primary="Chelsea Otakan" />
          </ListItem>
        })
      }
    </List>
  );
}